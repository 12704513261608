<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card style="border-radius: 30px !important;">

      <v-row no-gutters justify="center">

        <!-- Title + Close icon -->
        <v-col cols="12" class="mt-6">
          <v-row no-gutters>
            <v-col cols="8" class="offset-2">
              <h2 class="font-weight-bold text-center" style="margin:0 auto !important;">{{$t('account.profile.deleteDialog.title')}}</h2>
            </v-col>
            <v-col cols="1" class="offset-1">
              <v-btn icon @click="close">
                <v-icon color="primary">fal fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="10" offset-md="1" class="mt-8 text-center">
          <p class="font-weight-bold">{{$t('account.profile.deleteDialog.subtitle1')}}</p>
          <p class="font-weight-bold">{{$t('account.profile.deleteDialog.subtitle2')}}</p>
          <p class="font-weight-bold">{{$t('account.profile.deleteDialog.subtitle3')}}</p>
        </v-col>

        <!-- Switch -->
        <v-col cols="12" md="8" offset-md="2">
          <v-checkbox
            v-model="agree"
            :label="$t('account.profile.deleteDialog.agree')" />
        </v-col>

        <!-- Buttons -->
        <v-col cols="12" class="my-6">
          <v-row no-gutters justify="space-between" class="mx-4">
            <v-btn
              @click="customSubmit"
              rounded
              :disabled="!(!!agree)"
              width="150"
              color="error"
              :loading="isLoadingBtnSave"
              style="border-radius: 10px !important;font-size: 16px !important;"
            >
              {{$t('account.profile.deleteDialog.buttons.submit')}}
            </v-btn>

            <v-spacer />
            <v-btn
              rounded
              width="150"
              color="primary"
              @click="close(false)"
              style="border-radius: 10px !important;">
              {{$t('common.buttons.cancel')}}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

    </v-card>
  </v-dialog>
</template>

<script>
  import dialogMixin from "@/mixins/dialog"

  export default {
    name: "ProfileDeleteDialog",

    mixins: [dialogMixin],

    data()
    {
      return {
        isLoadingBtnSave: false,
        agree: false
      }
    },

    methods: {
      customSubmit() {

        this.isLoadingBtnSave = true;
        this.$http.delete(`/map/users/${this.$session.get("id")}`,
        {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          this.$store.commit("toast/showSuccess", this.$t('account.profile.deleteDialog.success'));
          this.close(true);
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
      }
    }
  }
</script>

<style scoped>

</style>